import { Head } from 'components/Head'
import Layout from 'components/Layout'
import { Footer } from 'components/home/Footer'
import { Box } from 'components/primitives'
import { NextPage } from 'next'
import NFTBanner from 'components/newhome/NFTBanner'


const Home: NextPage = () => {

  return (
    <Layout>
      <Head />
      <Box
        css={{
          p: 0,
          height: '100%',
          '@bp800': {
            px: '0',
          },
          '@xl': {
            px: '0',
          },
        }}
      >
          <Box
            css={{
              height: '100%',
            }}
          >
            <NFTBanner />
          </Box>
        </Box>
      <Footer />
    </Layout>
  )
}

export default Home
